import React from 'react';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from 'react-country-flag';
import Paper from '@material-ui/core/Paper';
import ExternalLink from '@material-ui/core/Link';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Location } from '@reach/router';
import LocalizedLink from '../utils/localized-link';
import { localizeUrl } from '../utils/localization';
import i18next from '../i18n/config';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "8px",
    background: 'none',
  },
  icon: {
    color: '#dfac47',
    fontSize: '24px',
    margin: '5px',
    transition: 'all .3s',
    '&:hover': {
      color: '#dfac00',
    },
  },
}));

function Footer({ className, language }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation('translations', { i18n: i18next });

  const handleChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  const socialSections = [
    {
      title: 'Github',
      icon: faGithub,
      link: 'https://github.com/8byr0',
    },
    {
      title: 'LinkedIn',
      icon: faLinkedin,
      link: 'https://www.linkedin.com/in/hugues-bureau/',
    },
  ];

  return (
    <footer
      className={className}
      css={{
        background: `#292929`,
        color: `#A1A1A1`,
        // padding: `1.45rem 0`,
        paddingTop: `25px`,
        '@media (max-width: 768px)': {
          textAlign: `center`,
        },
      }}
    >
      <div
        css={{
          width: `100%`,
        }}
      >
        {/* <Newsletter /> */}
        <div
          css={{
            maxWidth: `1200px`,
            margin: `auto`,
            display: `flex`,
            flexDirection: `row`,
            '@media (max-width: 768px)': {
              flexDirection: `column`,
            },
          }}
        >
          {/* <div css={{ flex: 4 }} /> */}
          <div
            css={{
              flex: 4,
              '@media (max-width: 768px)': {
                flexGrow: 1,
              },
            }}
          >
            {/* <h4 css={{ marginBottom: `10px`, fontFamily: `LatoLatinWebLight` }}>Blog</h4> */}
            <a
              className="twitter-timeline"
              data-dnt="true"
              data-theme="dark"
              data-width="250"
              data-height="300"
              href="https://twitter.com/8bYr0?ref_src=twsrc%5Etfw"
            >
              {/* Tweets by 8bYr0 */}
            </a>
          </div>
          <div
            css={{
              flex: 4,
              '@media (max-width: 768px)': {
                flexGrow: 1,
              },
            }}
          >
            <h4 css={{ marginBottom: `10px`, fontFamily: `LatoLatinWebLight` }}>{t('About')}</h4>
            <ul css={{ margin: 0, listStyleType: `none` }}>
              <li>
                <LocalizedLink to="/about/experiences">{t('Experiences')}</LocalizedLink>
              </li>
              <li>
                <LocalizedLink to="/about/skills">{t('Skills')}</LocalizedLink>
              </li>
              <li>
                <LocalizedLink to="/about/certifications">{t('Certifications')}</LocalizedLink>
              </li>
            </ul>
          </div>
          <div
            css={{
              flex: 4,
              '@media (max-width: 768px)': {
                flexGrow: 1,
              },
            }}
          >
            <h4 css={{ marginBottom: `10px`, fontFamily: `LatoLatinWebLight` }}>{t('Support')}</h4>
            <ul css={{ margin: 0, listStyleType: `none` }}>
              <li>
                <LocalizedLink to="/contact">{t('Contact')}</LocalizedLink>
              </li>
              <li>
                <LocalizedLink to="/legal">{t('Legal')}</LocalizedLink>
              </li>
              <li>
                <LocalizedLink to="/privacy">{t('Privacy')}</LocalizedLink>
              </li>
            </ul>
          </div>
        </div>
        <Paper
          css={{
            margin: `auto`,
            textAlign: `center`,
          }}
          elevation={0}
          className={classes.paper}
        >
          {socialSections.map((social) => (
            <ExternalLink
              color="inherit"
              href={social.link}
              className={classes.icon}
              target="_blank"
              key={social.title}
            >
              <FontAwesomeIcon icon={social.icon} />
            </ExternalLink>
          ))}
        </Paper>
      </div>
      <div
        css={{
          margin: `auto`,
          textAlign: `center`,
        }}
      >
        {/* <ReactCountryFlag
                    css={{ margin: `5px`, cursor: `pointer` }}
                    onClick={(e) => handleChange('fr')}
                    countryCode="FR"
                    svg
                /> */}
        <Location>
          {({ location }) => (
            <>
              <Link to={`${localizeUrl('fr', location.pathname.replace(`/${language}/`, `/`))}`}>
                <ReactCountryFlag
                  css={{ margin: `5px`, cursor: `pointer` }}
                  onClick={(e) => handleChange('fr')}
                  countryCode="FR"
                  svg
                />
              </Link>
              <Link to={`${localizeUrl('en', location.pathname.replace(`/${language}/`, `/`))}`}>
                <ReactCountryFlag
                  css={{ margin: `5px`, cursor: `pointer` }}
                  onClick={(e) => handleChange('en')}
                  countryCode="GB"
                  svg
                />
              </Link>
            </>
          )}
        </Location>
      </div>
      <div
        css={{
          textAlign: `center`,
          fontSize: `11px`,
        }}
      >
        {`©${new Date().getFullYear()} Hugues BUREAU`}
      </div>
    </footer>
  );
}

export default Footer;
