import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@material-ui/core';
import Header from './header';
import Footer from './footer';
import '../fonts/LatoLatin/latolatinfonts.css';
import './layout.css';
import PageTitle from './page-title';
import SEO from './seo';
import i18next from '../i18n/config';
import { StylesProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import CookieConsent from 'react-cookie-consent';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const LayoutContent = ({
  beforeMain,
  children,
  title,
  showTitle,
  cover,
  noHorizontalPadding,
  noVerticalPadding,
  hideTopPadding,
  headerBackground,
  language,
  lang,
}) => {
  const { t, i18n } = useTranslation('translations', { i18n: i18next });
  const { language: lng } = i18n;

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  useEffect(() => {
    const actualLang = i18n.language;
    const actualUrl = window.location.pathname;
    if (lang) i18n.changeLanguage(lang);
    // if (!actualUrl.includes(`/${actualLang}/`)) {
    //     if (actualLang !== i18n.options.fallbackLng[0]) {
    //         i18n.changeLanguage(i18n.options.fallbackLng[0]);
    //     } else {
    //         const lng = window.location.pathname.split('/')[1];
    //         if (languages.includes(lng)) {
    //             i18n.changeLanguage(lng);
    //         }
    //     }
    // }
  }, [i18n, lang]);

  return (
    <>
      <CookieConsent
        overlay={false}
        location="bottom"
        buttonText={t('Accept')}
        enableDeclineButton
        declineButtonText={t('Decline')}
        // flipButtons
        style={{ background: 'rgba(0,0,0,.95)', maxWidth: '500px', borderRadius: '0 5px 0 0' }}
        cookieName="gatsby-gdpr-google-analytics"
        buttonStyle={{ background: '#dfac47' }}
        declineButtonStyle={{ color: '#F1F1F1', background: 'none' }}
      >
        {t(
          'My website uses cookies to improve user experience and measure content efficiency.'
        )}
      </CookieConsent>
      <SEO title={title} />

      <Header siteTitle={data.site.siteMetadata.title} background={headerBackground} />
      {!hideTopPadding && <div css={{ height: `125px` }} />}
      {beforeMain}
      <div
        css={{
          margin: `0`,
          paddingTop: 0,
          minHeight: `calc(100vh - 125px)`,
          display: `flex`,
          flexDirection: `column`,
        }}
      >
        {cover && cover}

        <main
          css={{
            maxWidth: 1200,
            padding: noHorizontalPadding ? `` : `0px 1.0875rem 1.45rem`,
            paddingTop: noVerticalPadding ? `` : `1.8rem`,
            width: `100%`,
            background: `#333333`,
            margin: `20px auto`,
          }}
        >
          {showTitle && <PageTitle title={title} />}
          <div className="main">{children}</div>
        </main>
        <div css={{ flexGrow: 1 }} />
        <Footer />
      </div>
    </>
  );
};

const LayoutWrapper = (props) => {
  return (
    <ThemeProvider theme={darkTheme}>
      <StylesProvider injectFirst>
        <LayoutContent {...props} />
      </StylesProvider>
    </ThemeProvider>
  );
};

LayoutWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutWrapper;
