import React from 'react';
import './header.css';
import SvgLogo from '../images/logo.svg';
import { withTrans } from '../i18n/withTrans';
import LocalizedLink from '../utils/localized-link';
import LangSwitcher from './lang-switcher';

const Header = ({ background, language }) => {
  return (
    <>
      <header
        style={{
          background: background || `rgba(33, 33, 33, 0.90)`,
          position: `fixed`,
          left: 0,
          right: 0,
          top: 0,
          zIndex: 35,
          transition: `all .5s ease-out`,
        }}
      >
        <div
          css={{
            margin: `0 auto`,
            maxWidth: 1200,
            display: `flex`,
            alignContent: `center`,
            alignItems: `center`,
            justifyContent: `center`,
            fontFamily: `CoreSansNR45Regular`,
            fontSize: `17px`,
            flexDirection: `column`,
          }}
        >
          <div css={{ display: `flex`, flexDirection: `row`, width: `100%` }}>
            <div css={{ display: `flex`, flex: `4` }} />
            <div css={{ display: `flex`, flex: `4`, justifyItems: `center`, justifyContent: `center` }}>
              <LocalizedLink to="/">
                <div
                  className="profile-picture"
                  css={{
                    width: `60px`,
                    height: `60px`,
                    overflow: `hidden`,
                    margin: `10px`,
                    borderRadius: `900px`,
                  }}
                >
                  <img src={SvgLogo} alt="Logo Hugues BUREAU" />
                </div>
              </LocalizedLink>
            </div>
            <div css={{ display: `flex`, flex: `4`, justifyItems: `center`, justifyContent: `flex-end` }}>
              <LangSwitcher />
            </div>
          </div>
          <div
            css={{
              display: `none`,
              '@media (max-width: 768px)': {
                display: `flex`,
                flexGrow: 1,
              },
            }}
          />
        </div>
      </header>
    </>
  );
};

export default Header;
