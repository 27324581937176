import React from 'react';
import { withTrans } from '../i18n/withTrans';

const PageTitle = ({ title }) => {
  return (
    <h1
      className="pageTitle"
      css={{
        textTransform: `uppercase`,
        margin: `auto`,
        fontFamily: `LatoLatinWebLight`,
        fontWeight: 200,
        textAlign: `center`,
      }}
    >
      {title}
    </h1>
  );
};

export default withTrans(PageTitle);
