import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from './config';
import { useForceUpdate } from '../hooks/use-forced-update';

export function withForceUpdate(WrappedComponent) {
  return function WrappedWithTranslations(props) {
    const { i18n, t } = useTranslation('translations', { i18n: i18next });
    const forceUpdate = useForceUpdate();

    useEffect(() => {
      forceUpdate();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);

    return <WrappedComponent {...props} t={t} />;
  };
}
