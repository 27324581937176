import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { Link } from 'gatsby';
import Select from '@material-ui/core/Select';
import ReactCountryFlag from 'react-country-flag';
import { Location } from '@reach/router';
import { localizeUrl } from '../utils/localization';
import i18next from '../i18n/config';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: `10px`,
    flexDirection: `column`,
    display: `flex`,
    justifyContent: `center`,
  },
  selectEmpty: {
    marginTop: '16px',
  },
}));

const LangSwitcher = () => {
  const classes = useStyles();
  const { i18n } = useTranslation('translations', { i18n: i18next });

  const langs = useMemo(() => {
    return [
      {
        value: 'fr',
        countryFlag: 'FR',
      },
      {
        value: 'en',
        countryFlag: 'GB',
      },
    ];
  }, []);

  return (
    <Location>
      {({ location }) => (
        <FormControl variant="outlined" className={classes.formControl}>
          {i18n.language && (
            <Select
              id="demo-simple-select-outlined"
              value={i18n.language}
              style={{
                padding: 0,
                maxHeight: `40px`,
                borderColor: 'white',
              }}
            >
              {langs.map((data) => (
                <MenuItem
                  key={data.value}
                  value={data.value}
                  component={Link}
                  onClick={(e) => i18n.changeLanguage(data.value)}
                  to={localizeUrl(data.value, location.pathname.replace(`/${i18n.language}/`, `/`))}
                >
                  <ReactCountryFlag countryCode={data.countryFlag} svg />
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
      )}
    </Location>
  );
};

export default LangSwitcher;
