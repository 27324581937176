import React, { useMemo } from 'react';
import { Link } from 'gatsby';
import { withTrans } from '../i18n/withTrans';
import { localizeUrl } from './localization';

function LocalizedLink(props) {
  const to = useMemo(() => {
    return localizeUrl(props.language, props.to);
  }, [props]);
  return (
    <Link className={props.className} css={props.css} style={props.style} to={to}>
      {props.children}
    </Link>
  );
}

export default withTrans(LocalizedLink);
