import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Cache from 'i18next-localstorage-cache';
import { languages, defaultLanguage } from './settings';

i18next
    // .use(LanguageDetector)
    .use(Cache)
    .init({
        fallbackLng: defaultLanguage,
        resources: {
            fr: {
                translations: require('../locales/fr/translation.json'),
            },
            en: {
                // translations: require('../locales/en/translation.json'),
            },
        },
        ns: ['translations'],
        defaultNS: 'translations',
        returnObjects: true,
        // debug: process.env.NODE_ENV === 'development',
        interpolation: {
            escapeValue: false, // not needed for react!!
        },
        react: {
            useSuspense: true,
        },
        whitelist: ['en', 'fr'],
    });

i18next.languages = languages;

export default i18next;
